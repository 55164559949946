import React from 'react';

function ErrorPage() {
  return (
    <div>
        <h1>ERROR 404</h1>
    </div>
    
  )
}

export default ErrorPage;